import {debounce} from "lodash";
$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    let form = $('.js-confirmation-form');
    let container = $('.js-search-results');
    let input = $('.js-search-input');

    $(document).on('keypress',function(e) {
        if(e.which == 13 && input.is(":focus")) {
            e.preventDefault();
            $('.js-show-all-search').click();
        }
    });

    const sendRequest = function (e) {
        let ev = $(e.currentTarget);
        if($.trim(ev.val()) !== ''){
            let url = ev.attr('data-url');
            form.attr('action', url);
            form.append(`<input type="text" name="search_string" value="${ev.val()}"/>`);
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                success: function (response) {
                    container.html(response)
                },
                error: function (response) {
                }
            });
        } else {
            container.empty();
            return
        }
    }
    // input.val(new URLSearchParams(location.search).get('search') || '')
    input.on('input', debounce (sendRequest ,  500 ))
});
